<template>
  <div>
  
    <b-card >
      <b-row >
       <b-col> {{ $t("SearchPatient") }}</b-col>
       <b-col> <vs-input
            name="Patient Name"
            :placeholder="$t('PatientName')"
            v-model="Search.PatientName"
            style="width:100%"
          /></b-col>
       <b-col>  <feather-icon
            icon="SearchIcon"
            svgClasses=" w-16"
            @click="searchData(2)"
          /></b-col> 
         
        
      </b-row>
    </b-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="Model"
          >
            <template slot="thead">
              <vs-th >{{ $t("Patient") }}</vs-th>
              <vs-th > {{ $t("Action") }}</vs-th>
              <vs-th > {{ $t("NextAppointment") }} </vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <imageLazy
              :imageStyle="{ height: '40px', width: '40px' }"
              :imageClass="'rounded-full'"
              :src="baseURL + item.ProfileImagePath"
              placeHolderType="patient"
            />
            <p class="product-name font-medium truncate">
              {{ item.Name }}
            </p>
                  </vs-td>
                  <vs-td>
                    <div>
              <a style="text-decoration: underline;" @click="showPatientMedicalHistory(item.ID)" class="linkClass">
                {{ $t("PatientMedicalHistory") }}
              </a>
            </div>

            <div>
              <a style="text-decoration: underline;" @click="showPatientReservationHistory(item.ID)" class="linkClass">
                {{ $t("ReservationHistory") }}
              </a>
            </div>
                  </vs-td>
                  <vs-td>
                    <h5>
                
                {{ item.NextAppointmentDate }}
              </h5>
                  </vs-td>
                
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
        
        <b-row >
        <b-col >
          <div class="mt-8 ">
            <b-button   @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import {
BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue';
export default {
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      Search: {},
      Model: [],
    };
  },
  components: {
    imageLazy,BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow

  },
  methods: {
    showPatientMedicalHistory(PatientID) {
      debugger;
      this.$router.push({
        name: "PatientMedicalHistorySession",
        params: { ID: PatientID },
      });
    },
    showPatientReservationHistory(PatientID) {
      this.$router.push("/PatientReservation/" + this.$route.params.ID + "/" + PatientID);
    },
    back() {
      this.$router.go(-1);
    },
    searchData(index) {
      debugger;
      if (this.$route.params.ID) {
        var model = {};
        model.doctorID = this.$route.params.ID;
        if (index == 2) {
          if (this.Search.PatientName != "") {
            model.PatientName = this.Search.PatientName;
          }
        }
        this.$store
          .dispatch("DoctorList/GetDoctorPatients", model.doctorID)
          .then((res) => {
            if (res.status == 200) {
              debugger;

              this.Model = res.data.Data;
              debugger;
              //.filter(obj=>obj.Name.includes( model.PatientName ));
              if (
                (index =
                  2 &&
                  this.Search.PatientName != undefined &&
                  this.Search.PatientName != "")
              )
                this.Model = res.data.Data.filter((obj) =>
                  obj.Name.includes(model.PatientName)
                );
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.searchData(1);
  },
};
</script>

<style lang="scss">

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

</style>
